import moment from "moment";

window.App = {};

window.App.daterangepickerRanges = {
  'Today': [moment().startOf('day'), moment().endOf('day')],
  'Yesterday': [moment().subtract(1, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day')],
  'Previous 7 Days': [moment().subtract(8, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')],
  'Current Month': [moment().startOf('month'), moment().endOf('day')],
};
