module.exports = function(element){

  const $element = $(element)

  // Require all initializers
  const req = require.context('../initializers/', true, /\.js$/)
  req.keys().map(req).map(init => {

    // Run each initializer
    init.default($element)

  })

}
