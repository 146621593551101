const BootstrapDialog = require('bootstrap-dialog')

// Manage bootstrap dialog
module.exports = (function(){

  // Closes the top dialog
  const closeDialog = function(){

    // Find the top modal and issue a close event
    $(".modal").last().modal("hide")
  }

  // Closes All Dialog
  const closeAllDialog = function(){

    // Find the top modal and issue a close event
    $(".modal").modal("hide")
  }

  const requests = {}
  const openDialog = function(url){

    // Debounce the request if being processed
    if (requests[url]) return

    requests[url] = true

    // Perform the AJAX request
    $.get(url).always(() => requests[url] = false).then(

      // Success
      (response, status, jqXHR) => {

        // Build a modal
        const $modal = $(response)

        // Show error if response is not a modal
        if($modal.length > 1){
          Dialog.openCustomDialog({
            title: "Server Error",
            message: $(`
              <div class="f f-cols a-center">
                <img class="server-error-status"></img>
                <p>The modal has a body tag, please set layout to false to render modal properly.</p>
              </div>
            `)
          })

        } else {

          // Set up plugins
          initialize($modal)

          // Add the $modal
          $("BODY").append($modal)

          // Remove on close
          $modal.on("hidden.bs.modal", () => {
            $modal.remove()
          })

          // Handle tab selections
          const hash = url.split("#")[1]
          if (hash){
            $modal.find(`A[href='#${hash}']`).tab('show')
          }

          // Find an input and focus on it
          $modal.on("shown.bs.modal", () => {

            // All available inputs under modal-dialog class except froala buttons
            const inputs = $modal.find(".modal-body :input").not(".fr-command").not(":disabled")
            const autofocus = inputs.filter("[autofocus]")

            // Find the autofocus field or the first element if not specified
            if(autofocus.length > 0){
              autofocus.last().focus()
            } else if(inputs.length){
              inputs[0].focus()
            }

          })

          // Open it
          $modal.modal()

        }

      },

      // Failure
      actions.handleAPIError({
        url: url,
        method: "GET"
      })

    )
  }

  const openCustomDialog = function(overrides){
    const defaults = {
      closable: true,
      cssClass: "engage status-dialog center-dialog",
      buttons: [{
        label: "OK",
        cssClass: 'btn-primary',
        hotkey: 13, // Enter key
        action: (dialog) => { dialog.close() }
      }]
    }

    return BootstrapDialog.show(_.merge(
      {},
      defaults,
      overrides
    ))
  }

  return {
    openDialog: openDialog,
    openCustomDialog: openCustomDialog,
    closeDialog: closeDialog,
    closeAllDialog: closeAllDialog
  }
})()
