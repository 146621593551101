export default (state = {}, action) => {

  // Opens a dialog
  if (action.type == "dialog/open"){
    Dialog.openDialog(action.payload.url)
  }

  // Closes the top dialog
  if (action.type == 'dialog/close'){
    Dialog.closeDialog()
  }

  // Closes All Dialog
  if (action.type == 'dialog/closeAll'){
    Dialog.closeAllDialog()
  }

  return state

}
