/*
  Manage signout
*/
$(document).on("click", ".c-logout", function(event){

  event.preventDefault()

  Dialog.openCustomDialog({
    title: "Confirm Sign Out",
    message: $("<p>Are you sure you want to sign out?</p>"),
    buttons: [
      {
        label: "Sign out",
        cssClass: 'btn-primary',
        action: () => {

          // Clear localStorage
          localStorage.clear()

          // Redirect to link
          window.location = $(event.target).attr("href")

        }
      }
    ]
  })
})
