/*
  Handle editing offense code on category page
  * Add offense code to param
  * Remove offense code from params when 'x' button is clicked
  * Search functionality on the select.chosen element
  * Add italics to html output for offense codes that is assigned to a category
*/
export default function($element){

  // Initialize the library and make sure its italic
  $element.find(".chosen.js-category-code").each(function(idx){
    const $wrapper = $(this)
    const $form = $wrapper.parents("FORM")
    const $list = $form.find(".js-offense-code-list")

    $wrapper.chosen({
      placeholder_text_single: "Select an offense code",
      width: "100%",
      no_results_text: "No offense code found for ",
    })
    .on('chosen:showing_dropdown', (e) => {
      insertItalics($form.find(".chosen-results"))
    })

    // Read italics when user search for offense code
    $form.find(".chosen-search INPUT").on("keyup", function(e){
      insertItalics($form.find(".chosen-results"))
    })

    $wrapper.on("change", function(e, params){

      let index
      const val = params.selected
      const $selected_option = $wrapper.find(`OPTION[value=${val}]`)

      // Find id index if its already exist or create a new index
      // This is necessary for user that deletes offense code and readded it
      const $hidden_input = $form.find(`INPUT[data_oc_id=${val}]`)
      if ($hidden_input.length) {
        index = _.parseInt($hidden_input.attr("name").match(/[0-9]+/))
      } else {
        index = $form.find('INPUT[type=hidden][name*=id]').length
      }

      const $list_item = $(`
        <div class="c-selection-list__item f" data-id="${val}">
          ${$selected_option.text()}
          <span class="c-selection-list__close-icon"></span>
          <input type='hidden' name='category_map[]' value='${val}'></input>
        </div>
      `)

      // Display offense code on the list
      $list.append($list_item)

      // Disable option
      $selected_option.attr("disabled", true)
      $selected_option.html(`${$selected_option.html()} <i>– Assigned to this category.</i>`)

      // Clear the select selection
      $wrapper.val("")
      $wrapper.trigger("chosen:updated")

    })

    // Removing offense code from a category
    $list.on("click", ".c-selection-list__close-icon", (event) => {

      const $button = $(event.target)
      const $list_item = $button.parents(".c-selection-list__item")
      const offense_code_id = $list_item.data("id")
      const $option = $wrapper.find(`OPTION[value=${offense_code_id}]`)

      // Remove offense code
      $list_item.remove()

      // Enable back the select for that option
      $option.prop("disabled", false)
      $option.html($option.data('name'))
      $wrapper.trigger("chosen:updated")

    })
  })


  //================================================================
  // Italic the offense code list that is assigned to that category
  function insertItalics(chosenElement){
    const str = "– Assigned to this category"
    chosenElement.find(`li.disabled-result:contains(${str})`).each(function(i, ele){
      let changed_text = _.replace($(ele).text(), str, `<i>${str}</i>`)
      $(ele).html(changed_text)
    })
  }
}
