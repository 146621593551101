var editorCss = null

export default function($element){

  $element.find("DIV.froala-editor-wrapper").each(function(idx){

    editorCss = editorCss || $.get(`${BASE_URL}messages/stylesheet.css`)

    const $wrapper = $(this)
    const $editor = $wrapper.find("TEXTAREA")
    const variables = $wrapper.data().variables
    const stylesheet = $wrapper.data().stylesheet
    const isSms = $wrapper.hasClass("sms")
    const isLetterhead = $wrapper.hasClass("letterhead")
    const isInsideModal = $wrapper.parents(".modal").length >= 1

    // Initialize Froala custom dropdown
    $.FroalaEditor.RegisterCommand('Insert Variable', {
      title: 'Insert Variable',
      type: "dropdown",
      options: variables, // Disabled if HTML option is on
      focus: true,
      undo: true,
      refreshOnShow: function($btn, $dropdown){

        // Scroll to top on opening dropdown
        $dropdown.find(".fr-dropdown-content").scrollTop(0)

      },
      callback: function(cmd, val, params) {
        this.html.insert(val)
      }
    })

    // Set SMS toolbar
    const smsToolbar = [
      'Insert Variable', // Add the custom toolbar
      'undo',
      'redo'
    ]

    // Set email toolbar
    const emailToolbar = [
      'Insert Variable', // Add the custom toolbar
      'fontSize',
      'paragraphFormat',
      'color',
      'bold',
      'italic',
      'underline',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'insertLink',
      'undo',
      'redo',
      'clearFormatting'
    ]
    const toolbar = isSms ? smsToolbar : emailToolbar

    //  initialize Froala with its toolbar
    editorCss.then((styles) => {
      $editor.froalaEditor({

        // License key
        key: 'LC2A1B1A1sB4E4A3B12B3A7C6F2F4A3fhltzmtdakH3fij==',

        // Set Froala toolbar for different screen size
        toolbarButtons: toolbar,
        toolbarButtonsMD: toolbar,
        toolbarButtonsSM: toolbar,
        toolbarButtonsXS: toolbar,

        // Character counter enabled for both sms and letterhead
        charCounterCount: true,

        theme: "gray",
        fontFamilySelection: true,
        fontSizeSelection: true,
        fontSizeDefaultSelection: isLetterhead ? "12" : "14",
        paragraphFormatSelection: !isLetterhead,
        paragraphFormat: {
          N: 'Normal',
          H1: 'Heading 1',
          H2: 'Heading 2',
          H3: 'Heading 3',
          H4: 'Heading 4',
          H5: 'Heading 5',
          H6: 'Heading 6'
        },

        tabSpaces: 4, // Use Tab key for tab spaces
        enter: isSms ? $.FroalaEditor.ENTER_BR : $.FroalaEditor.ENTER_P,
        pastePlain: isSms,
        heightMin: 250,
        heightMax: 500,
        height: isInsideModal ? 250 : null,
        placeholderText: 'Type something here...',
        iframe: true,
        iframeStyle: styles + ` BODY, .fr-placeholder { padding: 1em; font-size: ${isLetterhead ? "12" : "14"}px; } .fr-box.fr-basic .fr-element.fr-view, P { background: transparent; font-size: ${isLetterhead ? "12" : "14"}px; }`
      })

      const iframe = $element.find("IFRAME")

      // Wait for animation to complete before set the iframe properly
      if (isInsideModal) {
        $wrapper.parents(document).on('shown.bs.modal', setIframeProperly)
      } else {
        setIframeProperly()
      }

      function setIframeProperly() {
        $editor.froalaEditor('size.syncIframe')
        $editor.froalaEditor('events.focus')
      }

      $wrapper.removeClass("invisible")

    })
  })
}
