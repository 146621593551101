export default function ($element) {
  $("A.c-api-key-management-form__add-privilege, A.c-api-key-management-form__add-ip-address").on("click", function () {
    const $element = $(this)
    const $input_copy = $element.prev().clone()
    if ($input_copy.children('INPUT.c-api-key-management-form__ip-address-field').length == 1) {
      // Create an empty text field for IP addresses using a copy of the existing text field
      $input_copy.children('INPUT.c-api-key-management-form__ip-address-field').val(null)
    } else {
      $input_copy.children('.c-api-key-management-form__privilege-select').val(null)
    }
    $element.before($input_copy)
  })

  $(document).on("click", ".c-api-key-management-form BUTTON.close", function () {
    if ($(this).closest('.form-group').children('DIV').length == 1) {
      // Unselect value from dropdown if its the last remaining dropdown
      $(this).prevAll('.c-api-key-management-form__privilege-select, .c-api-key-management-form__ip-address-field').val(null)
    } else {
      // Remove the dropdown
      $(this).parent().remove()
    }
  })
}
