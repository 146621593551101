import moment from 'moment'
import 'daterangepicker'

export default function ($element) {

  $element.find("DIV.data-table-filter__daterange").each(function (idx) {

    const $wrap = $(this)

    // Timestamp without timezone
    const FILTER_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS'
    const DISPLAY_FORMAT_WITH_TIME = 'MM/DD/YYYY hh:mm A'

    // Initialize the datepicker
    const datepicker = $wrap.find('INPUT.daterange').daterangepicker({
      opens: 'left',
      parentEl: '.data-table-filter',
      autoUpdateInput: false,
      timePicker: true,
      timePicker24Hour: true,
      alwaysShowCalendars: true,
      locale: {
        format: DISPLAY_FORMAT_WITH_TIME
      },
      ranges: App.daterangepickerRanges,
    })

    // Trigger apply.daterangepicker on ENTER key
    datepicker.on('keyup', event => {
      if (event.keyCode === 13) {
        $wrap.parents(".data-table-filter")
          .find(".daterangepicker BUTTON.applyBtn")
          .trigger('click')
      }
    })

    datepicker.on('apply.daterangepicker', (ev, picker) => {

      let startDate = picker.startDate
      let endDate = picker.endDate

      if (picker.chosenLabel === 'Custom Range') {
        startDate = picker.startDate.startOf('minute')
        endDate = picker.endDate.endOf('minute')
      }

      // Display the number in the UI
      datepicker.val(`${startDate.format(DISPLAY_FORMAT_WITH_TIME)} - ${endDate.format(DISPLAY_FORMAT_WITH_TIME)}`)

      $wrap.find("INPUT[name='start']").val(startDate.format(FILTER_FORMAT))
      $wrap.find("INPUT[name='end']").val(endDate.format(FILTER_FORMAT))

      // Trigger change event
      picker.element.change()

    })
  })
}
