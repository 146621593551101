/*
  Events listener to handle bootstrap modal
*/

// Ensure that the escape key closes the last modal
$(document).keyup(function(event){
  if (event.keyCode == 27) {
    if ($(".modal").length) {
      actions.closeDialog()
      event.preventDefault()
    }
  }
})

// Allow the body to be scrollable if additional modals are still open
$(document).on("hidden.bs.modal", function(event){

  if ($(".modal.in").length) {
    $("BODY").addClass('modal-open')
  }

})

// Ensure modal links are opened using a modal
$(document).on("click", "[data-use-modal]", function(event){

  // Catch the click
  event.preventDefault()

  const data = $(event.currentTarget).data()

  // Close the top modal
  if (data.useModal == "existing") {
    actions.closeDialog()
  }

  actions.openDialog({url: $(event.currentTarget).attr("href") || data.modalUrl})

})

// This will fix overlay z-index on multiple bootstrap dialogs
$(document).on('shown.bs.modal', '.modal', function(event){
  const zIndex = 1040 + (10 * $('.modal:visible').length)
  $(this).css('z-index', zIndex)
  setTimeout(function() {
    $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack')
  }, 0)
})
