/*
  Close Iframe on esc keypress
*/
export default function ($element) {
  $element.find("IFRAME.js-iframe-close-dialog").each(function (idx) {

    // Attach event after iframe is loaded
    $(this).on("load", () => {

      $(this.contentWindow.document).on('keyup', event => {

        // Close the dialog on esc keypress
        if (event.keyCode == 27) Dialog.closeDialog()
      })
    })
  })
}
