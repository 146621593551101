const store = require('./store')
const intents = require('./intents')

// function actionFactory(type){
//   return (payload, meta) => {
//     const action = {
//       type,
//       payload,
//       meta
//     }
//     store.dispatch(action)
//   }
// }

// Convert all intents to actions
_.forEach(intents, (intent, key) => {
  module.exports[key] = function convertedFromIntent() {
    store.dispatch(intent.apply(this, arguments))
  }
})

// Add actions that are not intent-based
_.merge(module.exports, {

  // Adds an intent to the stack
  // Arguments must be callback functions (and should be FSA thunks)
  addIntent(success, cancel){

    let action = {
      type: 'intents/add',
      payload: {
        success,
        cancel
      }
    }

    // Add the intent to the stack
    store.dispatch(action)

  },

  // Executes either the cancel or success action of the last intent
  executeIntent(type){

    // Ensure that an intent exists
    let state = store.getState().intents
    if(!state.length){
      throw "There are currently no intents on the stack."
    }

    // Remove the intent from the stack
    const intent = _.last(state)
    this.removeIntent()

    // Dispatch it
    switch(type){
      case "success":
        store.dispatch(intent.success)
        break;
      case "cancel":
        store.dispatch(intent.cancel)
        break;
      default:
        throw `Can't execute intent due to unrecognized type (${action.payload})`
    }

  },

  // Removes the top intent from the stack
  removeIntent(){

    let action = {
      type: 'intents/remove',
      payload: {}
    }
    store.dispatch(action)

  },

  // Handle sucess AJAX
  handleAPISuccess(){
    return function(res, status, xhr){
      store.dispatch({
        type: "api/success",
        res: res,
        status: status,
        xhr: xhr
      })
    }
  },

  // Gracefully handles AJAX errors
  handleAPIError(meta){
    return function(error){

      // Ouput the error
      console.warn(error)

      store.dispatch({
        type: 'api/error',
        payload: error,
        meta
      })

    }
  },

})
