let createStore = null

// Determine which store to pull in
if (process.env.NODE_ENV === 'production') {
  createStore = require('./prod')
}else{
  createStore = require('./dev')
}

// Initialize the store
const initialState = {}
module.exports = createStore(initialState)
