Sentry
  .init({
    dsn: 'https://9b251cec86f74912a31b7ade93f4c4c8@sentry.io/113066',
    denyUrls: [/localhost/, /127\.0\.0\.1/],
    ignoreErrors: [
      // Ignore error caused by `csv_controller.rb` rendering preview with `:unprocessable_entity`. Delete this when
      // we improve that process to not need the hack. The hack was for:
      // https://github.com/hotwired/turbo-rails/issues/12#issuecomment-754629885
      ".*handle_csv_upload.*422.*Unprocessable.*",
    ],
    beforeSend: function (event) {
      let errorMessage = event.message || event.exception.values[0].value;

      if (window.FS) {
        // Need to wait for the event to be sent in order to grab eventID
        setTimeout(() => {
          $.post(event.request.url.split('?')[0].replace('spotlight', 'track_error'), {
            url_address: event.request.url,
            full_story_link: FS.getCurrentSessionURL(true),
            error_name: errorMessage,
            sentry_event_link: `https://sentry.io/organizations/spidr-tech/?query=${event.event_id}`
          })
        }, 3000);
      }

      return event;
    },
  });

const user_info = JSON.parse(localStorage.getItem("user_info"))

Sentry.configureScope(scope => {
  scope.setUser(user_info);
});
