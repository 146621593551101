/*
  To enable sending message button
  User need to make a selection before able to send a message
*/
$(document).on("click", ".c-send-message-form .js-select-send-mode", function(event){

  const $wrapper = $(this).parents(".c-send-message-form")

  if (event.target.checked) {
    $wrapper.find("INPUT[type=submit]").attr("disabled", false)
  }

})
