export default function ($element) {

  $element.find("DIV.data-table-filter").each(function (idx) {

    const $wrap = $(this)
    const $outer_wrap = $wrap.find('.data-table-filter__outer-wrapper')

    $wrap.on('click', '.data-table-filter__button', event => {

      $outer_wrap.toggleClass('hidden')

    })

    // Clear filter click
    $wrap.on('click', '.data-table-filter__clear-filter', event => {
      const $parent = $(event.target).parent()

      // Clear division filter click
      if ($(event.target).hasClass("division")) {

        $parent.find("INPUT:checkbox").prop("checked", false)
        $parent.find("INPUT:checkbox[value='']").prop("checked", true).change()
      }

      // Clear date filter click
      if ($(event.target).hasClass("daterange")) {

        // Remove the date value and trigger datatable to rerender
        $parent.find("INPUT").val("").change()
        $parent.find("SPAN.js-date-label.start").html('Start')
        $parent.find("SPAN.js-date-label.end").html('End')

      }
    })

    $wrap.off("keyup.hideMenu").on("keyup.hideMenu", event => {
      if (event.keyCode == 27) {

        // Close if the daterange is open
        if ($(document).find(".daterangepicker").css("display") == 'block') {
          return $wrap.find(".data-table-filter__daterange").data('daterangepicker').hide()
        }

        hideMenu()

      }
    })

    // Handle closing dropdown
    $(document).off('click.hideMenu').on('click.hideMenu', event => {

      // Prevent closing if click on daterangepicker
      if ($(event.target).closest('.daterangepicker, .table-condensed').length) return

      // Hide the menu when the user click outside of the component boundary
      if (!$(event.target).closest('.data-table-filter').length) hideMenu()
    })

    function hideMenu() { $outer_wrap.addClass('hidden') }
  })
}
