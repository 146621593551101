/*
  Manage toggle redacted inputs on Victim Case and Request FOIA form.
*/
$(document).on("change", ".c-foia-redacted-collapse .js-toggle-redacted", function(event){

  const $wrapper = $(this).parents(".c-foia-redacted-collapse")

  $wrapper.find(".is-redacted-form, .is-not-redacted-form").collapse("hide")

  $(this).val() ? $wrapper.find(".is-redacted-form").collapse('show') : $wrapper.find(".is-not-redacted-form").collapse('show')
})
