/*
  When creating a new agency, county list will be populated based on selected state
*/
$(document).on('change', '.c-state-select', function(event){

  const $state = $(event.target)
  const state_id = $state.val()
  const $county_select = $state.parents("FORM").find(".c-county-select")
  let select_options = `<option value>Please select</option>`

  if (state_id) {
    $.get(`/root_admin/county_by_state/${state_id}`)
    .done((data) => {
      data.forEach((county) => {
        select_options += `<option value="${county.id}">${county.name}, ${county.abbrev}</option>`
      })
      $county_select.html(select_options)
    })
  } else {
    $county_select.html(select_options)
  }

})
