/*
  Manage templates list dropdown
*/
$(document).on("click", ".c-template-dropdown .js-template-dropdown", function(event){
  event.preventDefault()

  const type = event.target.dataset.type
  const mode = event.target.dataset.mode

  const $wrapper = $(this).parents(".c-template-dropdown")
  const $templates = $wrapper.find(`.c-navigation-wrapper[data-type=${type}][data-mode=${mode}]`)


  // Change state of open/closed dropdown arrows
  $wrapper.find(".js-template-dropdown").each((i, button) => {
    if (button != event.target) {
      $(button).removeClass('open')
    } else {

      // Add a down/open arrow to the clicked button
      $(event.target).toggleClass("open")
    }
  })

  // Close other navigation-wrapper
  $wrapper.find(".c-navigation-wrapper").each((i, wrap) => {
    if(wrap.dataset.type == type && wrap.dataset.mode == mode) return

    // Add hidden class name to the other wrapper
    $(wrap).addClass("hidden")
  })

  if ($templates.length) {
    $templates.toggleClass("hidden")
  }

  // bind esc key to close
  $(document).keyup(function(event){
    if (event.keyCode == 27) {
      $templates.addClass("hidden")
      $(event.target).removeClass("hidden")
    }

  })
})
