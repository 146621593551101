/*
  Initialize a select2 library for all dropdown that has .select2 class
*/
export default function($element){
  $element.find(".select2").each(function(idx){

    const options = {}
    const $dropdown = $(this)

    // Change dropdown parent so BS modal doesn't think you're exiting the modal when clicking on Select2 elements
    if ($element.hasClass("modal")) {
      options["dropdownParent"] = $element
    }

    // Initialise
    $dropdown.select2(options)

    // Show the select2 after being initialized properly
    $dropdown.removeClass("hidden")
  })
}
