// Load global libraries
window.$ = window.jQuery = require('jquery')
window._ = require('lodash')
window.Redux = require('redux')
window.actions = require('./flux/actions')
window.intents = require('./flux/intents')
window.Sentry = require("@sentry/browser")

// Load the base URL
window.BASE_URL = $("META[name=base_url]").attr("content")
window.DEBOUNCE_THRESHOLD = 350

// Try to delete this after webpack is gone. It prevents an error that occurs when making a JS function `async`.
// I specifically got it when I made an event handler `async`.
// NOTE: If you delete this, you should try to delete it in other JS entrypoints, too.
import "regenerator-runtime/runtime";

// Load redux
require('./flux/store')

// Load UI libraries
require('jquery-ui/ui/widgets/sortable')
require('bootstrap')
window.Dialog = require('lib/dialog')
require('chosen-js')

// Select2 and its default settings
require('select2')
$.fn.select2.defaults.set("theme", "bootstrap")
$.fn.select2.defaults.set("placeholder", "Select an option")

// Require date timepicker
require('bootstrap-datetime-picker')

// Require datepicker
require('bootstrap-datepicker')

// Froala ~ stuff
require('froala-editor/js/froala_editor.min.js')

// Froala ~ plugins
require("froala-editor/js/plugins/align.min.js")
require("froala-editor/js/plugins/colors.min.js")
require("froala-editor/js/plugins/font_family.min.js")
require("froala-editor/js/plugins/paragraph_format.min.js")
require("froala-editor/js/plugins/font_size.min.js")
require("froala-editor/js/plugins/inline_style.min.js")
require("froala-editor/js/plugins/link.min.js") // Enables advanced link editing
require("froala-editor/js/plugins/lists.min.js")
require("froala-editor/js/plugins/char_counter.min.js") // Character counter for the Froala editor

// JS Reporter
require("lib/sentry.js")

// Global constants and whatnot
require ('./lib/global')

// Load initalizer
window.initialize = require('./lib/initialize')

// DataTable stuff
require('datatables.net')
require('datatables.net-buttons-bs')
require('datatables.net-responsive-bs')

// qTip2
require('qtip2')

// Initialize the document
document.addEventListener("turbo:load", () => {
  initialize(document);
});

// Require all of the BEM modules
const bem = require.context('./bem/', true, /\.jsx?$/)
bem.keys().map(bem)

// Require all engage JS files
const req = require.context('./engage/', true, /\.jsx?$/)
req.keys().map(req)

// Use Hotwire Turbo.
// For some reason, seem to need to import @hotwired/turbo instead of @hotwired/turbo-rails. Importing the latter
// causes assorted JS to not work, and Turbo doesn't work either. Just visit the Messages view to see what I mean.
// There's certainly something wacky here going on, as the correct import should be @hotwired/turbo-rails. But it's
// hard to investigate because we're using webpack and not import maps yet. This should be adjusted once we move
// to import maps.
// NOTE: If you change this here, you probably want to change it in new.js, too.
import "@hotwired/turbo";

// Plug in Stimulus.
import "./controllers";

// Use Rails' official request library.
import "@rails/request.js";
