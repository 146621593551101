/*
  Manage toggle functionality of choosing between global template and custom template
*/
$(document).on("click", ".c-template-toggle .js-use-custom",
  _.debounce((event) => {
    const $checkbox = $(event.target)
    const is_checked = $checkbox.prop("checked")

    const $custom_wrap = $(event.target).parents("FORM").find(".custom")
    const $global_wrap = $(event.target).parents("FORM").find(".global")

    if(is_checked){

      $global_wrap.hide()
      $custom_wrap.show()

      // Focus to the editor
      $($custom_wrap.find("DIV.froala-editor-wrapper TEXTAREA")[0]).froalaEditor('events.focus')

      const iframe = $custom_wrap.find("IFRAME")

      // Set the height of the iframe to the height of the content
      iframe.height(
        iframe.contents().find("body").outerHeight()
      )

    }else{

      $custom_wrap.hide()
      $global_wrap.show()

    }
  }, window.DEBOUNCE_THRESHOLD)
)
