/*
  Initialize datepicker elements
*/
export default function($element){
  $element.find(".datepicker").each(function(idx){

    // Initialize datepicker
    const datepicker = $(this).datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      forceParse: false,
      orientation: "bottom"
    })

    // Prevent modal from closing on esc key
    datepicker.bind('keyup', (e) => {
      if (e.keyCode === 27) {
        e.preventDefault()
        e.stopImmediatePropagation()

        $(event.target).blur() // Remove focus on input to prevent opening datepicker on esc keyup.
        datepicker.parents(".dropdown").find(".dropdown-toggle").focus()
      }
    })

  })
}
