/*
  Initialize datetimepicker elements
*/
export default function($element){
  $element.find(".datetimepicker").each(function(idx){

    // Initialize datetime picker
    // For more formats, please refer: http://www.malot.fr/bootstrap-datetimepicker/demo.php
    $(this).datetimepicker({
      format: 'yyyy-mm-dd hh:ii',
      minView: 0,
      minuteStep: 15,
      autoclose: true
    });

  })
}
