import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()

export default phoneUtil

// Ouput phone number in local mode or E164 format
export function localizePhoneNumber(str, country_code = 'US') {
  if (!str) return str

  try {
    const number = phoneUtil.parseAndKeepRawInput(str, country_code)
    const region = phoneUtil.getRegionCodeForNumber(number)

    switch (region) {
      case 'CA':
      case 'US':
        return phoneUtil.format(number, PNF.NATIONAL)
      default:
        return phoneUtil.format(number, PNF.E164)
    }
  }
  catch (err) { return str }
}
