/*
  To enable and disable CAD custom delay time select input on toggling use custom delay
*/
$(document).on("change", ".c-cad-category-modal .js-toggle-cad-category-delay", function(e){

  const $component = $(this).parents(".c-cad-category-modal")
  const $input = $(e.target)
  const is_enabled = $input.prop("checked")

  $component.find(".js-select-delay").prop("disabled", !is_enabled)

})

/*
  To send CAD preview
*/
$(document).on("click", ".c-cad-category-modal .js-send-preview-button", function(e){

  const $component = $(this).parents(".c-cad-category-modal")
  const $button = $(e.target)
  const $input = $button.siblings("INPUT")
  const $preview_container = $component.find(".c-cad-category-modal__preview-container")

  const url = $button.data("path") + ".json"
  const phone_number = $button.parent().find("INPUT[name='send_preview[phone_number]']").val()
  const use_custom = $component.find("INPUT.js-use-custom").prop("checked")
  const text = use_custom ? $component.find("TEXTAREA[name='custom_section[content]']").val() : $component.find(".c-cad-category-modal__template.global").html().trim()

  e.preventDefault()

  // Remove error message
  $preview_container.find("LABEL, INPUT").removeClass("u-error-field u-error-text")
  $preview_container.find(".c-error-message").remove()

  $.get(url, {phone_number, text})
  .done((res, status, xhr) => {

    const success_dialog = Dialog.openCustomDialog({
      title: "",
      message: $(`
        <div class="f f-cols">
          <img class="success-status"></img>
          <p>Preview message has been sent.</p>
        </div>
      `)
    })

    // Close all success dialogs after 3.5 seconds
    setTimeout(() => success_dialog.close(), 3500)

    $button.prop("disabled", true).html("Sent")
    $input.one("focus", (e) => {
      $input.val("")
      $button.prop("disabled", false).html("Send Preview")
    })
  })
  .fail((err) => {

    // Output error message
    err.responseJSON.errors.forEach((e, i) => {
      let error_input = $preview_container.find(`INPUT[name*=${e.field}]`).addClass("u-error-field")
      error_input.siblings("LABEL").addClass("u-error-text")
    })

    $preview_container.append(`
      <div class="f f-cols c-error-message">
        ${err.responseJSON.errors.map((e) => `<span>*${e.error}</span>`).join("")}
      </div>
    `)

  })

})

/*
  Disable submit form when enter is pressed on send-preview-input and request send preview
*/
$(document).on("submit", ".c-cad-category-modal FORM", function(e){

  const $component = $(this).parents(".c-cad-category-modal")

  if ($(":focus").hasClass("js-send-preview-input")) {
    e.stopImmediatePropagation()
    $component.find(".js-send-preview-button").trigger("click")
  }

})
