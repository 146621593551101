/*
  Enable or disable the toggle input field based on whether a category is selected.
*/
$(document).on("change", ".c-offense-code-form .js-category-select", function(event){

  const $select = $(event.target)
  const $checkbox = $select.parents("FORM").find('.js-toggle')

  if ($select.val()) {
    $checkbox.prop("disabled", false)
    $checkbox.prop("checked", true)
    $checkbox.next().removeClass("cursor", "pointer")
  } else {
    $checkbox.prop("disabled", true)
    $checkbox.prop("checked", false)
    $checkbox.next().addClass("cursor", "not-allowed")
  }
})
