/*
  To update acknowledgement badge on the messages pages.
*/
document.addEventListener("turbo:load", (event) => {
  const $wrapper = $(this)
  const $link = $wrapper.find(".js-update-last-seen")

  // Update the badges when user types in the URL or visit from another page without changing the URL
  const $active_link = $wrapper.find(".js-update-last-seen.active")
  if ($active_link.length) {

    const $badge = $active_link.find(".c-last-seen-badges")

    // Only update if there is badge
    if ($badge.length) $.post(`${BASE_URL}last_seen_badges`, { parent_type: $badge.data("parentType"), message_status: $badge.data("messageStatus") })

  }

  // Update the acknowledgement when user click on the link.
  $link.on("click", function(event){

    // Disable link
    event.preventDefault()

    const $this = $(this)

    const $badge = $this.find(".c-last-seen-badges")

    if ($badge.length) {

      $.post(`${BASE_URL}last_seen_badges`, { parent_type: $badge.data("parentType"), message_status: $badge.data("messageStatus") })

        .done(res =>{

          $badge.addClass("hidden")
          window.location = $this.prop("href")
        })
    } else {

      window.location = $this.prop("href")
    }
  })
});
