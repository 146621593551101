export default (state = {}, action) => {

  // Handles API success to redirect / open dialog / refresh page
  if(action.type == "api/success"){

    // Get response message
    const response_message = action.xhr.getResponseHeader('X-success-message')

    // Redirect link
    const redirect_link = action.xhr.getResponseHeader("X-redirect-link")

    // Skip success message if requested
    const skip_success_msg = action.xhr.getResponseHeader("X-skip-success-message")

    // Open new page instead of dialog
    const open_new_page = action.xhr.getResponseHeader("X-open-new-page")

    // Open in current page
    const open_page = action.xhr.getResponseHeader("X-open-page")

    // Perform these actions
    const handleNextStep = function() {

      // Open in new page
      if (open_page) {
        return window.location = redirect_link
      }

      // Open in a new tab.
      if (open_new_page) {
        var win = window.open(redirect_link, '_blank');
        return win.focus();
      }

      // Redirect to link if its specified
      if(redirect_link){
        return Dialog.openDialog(redirect_link)
      }

      // Reload if nothing else
      return window.location.reload()

    }

    Dialog.closeAllDialog()

    if (!skip_success_msg){

      // Show success notification
      const success_dialog = Dialog.openCustomDialog({
        title: "",
        message: $(`
          <div class="f f-cols">
            <img class="success-status"></img>
            <p>${response_message || "The data is successfully submitted."}</p>
          </div>
        `),
        onhidden: function(dialogRef){ handleNextStep() }
      })

      // Close all success dialogs after 3.5 seconds
      setTimeout(() => success_dialog.close(), 3500)

    } else {

      // Success message has been skipped
      handleNextStep()
    }
  }

  // Handles API errors (bad network requests, etc.)
  if (action.type == "api/error"){

    const req = `\n\nRequested: <b>${action.meta.method.toUpperCase()} ${action.meta.url}</b>`

    switch(action.payload.status){
      case 400:

        // Bad requests for forms should show error messages
        if (action.meta.form && $(action.meta.form).length) {

          const $form = $(action.meta.form)

          // Parse the errors
          let errors = []

          // Contstruct the error(s)
          action.payload.responseJSON.errors.map((err) => {

            errors.push(err.error)

            // Highlight fields that have errors
            let $field = $form.find(`:INPUT[name*="${err.field}"]`)
            let $label = $field.siblings("label")
            let $select2 = $field.siblings('.select2').find('.select2-selection')

            // Apply error class to the field and label
            $label.addClass("u-error-text")
            $field.addClass("u-error-field")
            $select2.addClass("u-error-field")

            // Remove error class on change of field
            $field.on("change", function(e){
              $label.removeClass("u-error-text")
              $field.removeClass("u-error-field")
              $select2.removeClass("u-error-field")
            })

          })

          // Remove old error message
          $form.find(".c-error-message").remove()

          // Display the errors
          const template = `
            <div class="f f-cols c-error-message">
              ${errors.map((e) => `<span>*${e}</span>`).join("")}
            </div>
          `

          // Show either in modal or the form element
          if ($form.find(".modal-body").length) {

            $form.find(".modal-body").prepend(template)

          } else {

            $form.find("INPUT[type=submit]").parent().before(template)

          }

          // Ensure submit button is enabled after failed request
          $form.find("INPUT[type=submit]").prop("disabled", false)

        } else {

          Dialog.openCustomDialog({
            title: "Bad Request",
            message: $(`
              <div class="f f-cols a-center">
                <img class="bad-request-status"></img>
                <p>We're sorry, but your request could not be processed due to bad or missing data.${req}</p>
              </div>
            `)
          })

        }
        break;
      case 401:
        Dialog.openCustomDialog({
          title: "Not Logged In",
          message: $(`
            <div class="f f-cols a-center">
              <img class="unauthorized-status"></img>
              <p>It appears that you are not currently logged in. Your session may have expired. Please click the button to log back in.</p>
            </div>
          `),
          buttons: [{
            label: "Go to Login Screen",
            action: (dialog) => {
              dialog.close()
              window.location = `${BASE_URL}login`
            }
          }]
        })
        break;
      case 403:
        Dialog.openCustomDialog({
          title: "Forbidden",
          message: $(`
            <div class="f f-cols a-center">
              <img class="forbidden-status"></img>
              <p>We're sorry, but your account does not have the authorization neccessary to complete this request.${req}</p>
            </div>
          `)
        })
        break;
      case 404:
        Dialog.openCustomDialog({
          title: "Page Not Found",
          message: $(`
            <div class="f f-cols a-center">
              <img class="not-found-status"></img>
              <h3>Sorry!</h3>
              <p>We can’t seem to find the page you are looking for. Please check your URL or return to the homepage.</p>
            </div>
          `)
        })
        break;
      case 500:
        Dialog.openCustomDialog({
          title: "Server Error",
          message: $(`
            <div class="f f-cols a-center">
              <img class="server-error-status"></img>
              <h3>Sorry!</h3>
              <p>Something went wrong on our end. Our team has been notified and are taking measures to fix this.</p>
            </div>
          `)
        })
      default:
        console.warn("Uncaught status:", action.payload.status)
    }
  }

  return state

}
