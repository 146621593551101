import { localizePhoneNumber } from "../../src/lib/phonelib"
import timestampFormatter from "../../src/lib/timestampFormatter"

export default function($element){

  $element.find("DIV.data-table-wrapper").each(function(idx){

    const $wrapper = $(this)
    const table_data = $wrapper.find("TABLE.data-table").data()

    // Apply additional filter if enabled
    const setAdditionalFilterData = function(data){

      // To get value if exist
      const getValue = function(i, ele){
        let $ele = $(ele)
        if ($ele.val().length) return $ele.val()
      }

      // Set diivision filter
      if (table_data.show_division_filter) {
        data.division_filter = $wrapper.find(".division-filter INPUT:checked").map(getValue).get()
      }

      // Set date filter
      if (table_data.show_date_filter) {
        data.date_filter = $wrapper.find(".data-table-filter__daterange INPUT[type='hidden']").map(getValue).get()
        data.date_filter_label = $wrapper.find(".data-table-filter__daterange INPUT[name='label']").val()
      }

      // Set delivery mode filter
      if (table_data.show_delivery_mode_filter) {
        data.delivery_mode_filter = $wrapper.find('.delivery-mode-filter INPUT:checked').map(getValue).get()
      }

      // Set entity type filter
      if (table_data.show_entity_type_filter) {
        data.entity_type_filter = $wrapper.find('.entity-type-filter INPUT:checked').map(getValue).get()
      }

      // Set survey status filter
      if (table_data.show_survey_status_filter) {
        data.survey_status_filter = $wrapper.find(".survey-status-filter INPUT:checked").val();
      }

      // Set user status filter
      if (table_data.show_user_status_filter) {
        data.user_status_filter = $wrapper.find('.user-status-filter INPUT:checked').val();
      }
    }

    // Send additional filter data with the request before send
    $wrapper.on("preXhr.dt", function(e, settings, data){
      setAdditionalFilterData(data)
    })

    // Initialize the data table
    const table = $wrapper.find("TABLE.data-table").DataTable({

      // Set order by first column descending
      order: [[0, 'desc']],

      // Use the history state API to store the table's state
      stateSave: true,
      stateSaveCallback: function(settings, data){
        history.replaceState(data, null)
      },
      stateLoadCallback: function(settings, callback){
        callback(history.state)
      },
      stateLoadParams: function(settings, data){

        // Cleanup
        $wrapper.find(".division-filter INPUT").prop('checked', false)

        // Delivery mode filter default to show all
        $wrapper.find(".delivery-mode-filter INPUT").prop('checked', true)

        // Apply state to elements
        if (data.division_filter) {
          if (!data.division_filter.length) {$wrapper.find(".division-filter INPUT[value='']").prop('checked', true)}
          data.division_filter.forEach((val) => {
            $wrapper.find(`.division-filter INPUT[value=${val}]`).prop("checked", true)
          })
        }
        if (data.date_filter) {
          data.date_filter.forEach((val, i) => {
            $($wrapper.find(".date-filter INPUT[type='hidden']").get(i)).val(val)
          })

          data.date_filter_label &&
            $wrapper.find(".data-table-filter__daterange INPUT[name='label']")
              .val(data.date_filter_label)
        }

      },
      stateSaveParams: function(settings, data){
        setAdditionalFilterData(data)
      },
      dom: `t<'f-cols relative'ip>`,
      language: {
        paginate: { next: '^', previous: '^' }
      },
      fnDrawCallback: oSettings => {

        const $pagination = $wrapper.find('.dataTables_paginate')

        // Hide pagination when the shown rows is more than number of records
        if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
          $pagination.addClass('hidden')
        } else {
          $pagination.removeClass('hidden')
        }
      },
      columnDefs: [
        {
          targets: "entity_name",
          render: function ( data, type, full, meta ) {
            const url = `${BASE_URL}${full.entity_type}/${full.entity_id}`
            let icon = (type => {
              switch (type) {
                case 'contacts':
                  return 'fa-id-card-o'
                case 'businesses':
                  return 'fa-building-o'
                case 'persons':
                  return 'fa-user-o'
                default:
                  return ''
              }
            })(full.entity_type)

            return `<a href="${url}" data-use-modal="existing">
              <i class="fa ${icon}" aria-hidden="true"></i> ${data}
            </a>`
          }
        },
        {
          targets: "search_name",
          render: function ( data, type, full, meta ) {
            return `<img class="${full.entity_type}-icon" />${data}`
          }
        },
        {
          targets: "point_of_contact",
          render: function ( data, type, full, meta ) {
            return full.poc_id ? `
              <a href="${BASE_URL}persons/${full.poc_id}" data-use-modal="existing">
                <img class="persons-icon" />${data}
              </a>`
            : null
          }
        },
        {
          targets: "oc_name",
          render: function(data, type, full, meta) {
            return `<a href="${BASE_URL}admin/offense_codes/${full.id}" data-use-modal="new">${data}</a>`
          }
        },
        {
          targets: "offense_code",
          render: function(data, type, full, meta) {
            return `<a href="${full.DT_RowData.link}">${data}</a>`
          }
        },
        {
          targets: ["phone", "phone_number","recipient"],
          render: function(data, type, full, meta) {
            return localizePhoneNumber(data)
          }
        },
        {
          targets: "offense_code_description",
          render: function(data, type, full, meta) {
            return _.truncate(data, { length: 43 })
          }
        },
        {
          targets: [
            "automate_after", "call_timestamp",
            "survey_completed_timestamp",
            "message_status_timestamp",
            "created_at",
            "updated_at",
            "last_used"
          ],
          render: function(data, type, full, meta) {
            return timestampFormatter(data)
          }
        }
      ]
    })

    // It's probably not worth explaining this in detail since we're (hopefully) getting rid of datatables soon.
    // In short, those `*.dt` events are either (1) not "real" events that can be listened for with event listeners,
    // or, (2) they `need` to be listened for with `on` and only `on`. Or (3) something else. Either way, these lines
    // pick up on the processing events and then emit custom events which _can_ be picked up
    // by normal JS. These two custom events are listened for in our datatable Stimulus controller.
    $wrapper.on("processing.dt", function(event, settings, isProcessing) {
      let newEventString = isProcessing ? "datatableProcessingStart": "datatableProcessingDone";
      $wrapper.find("TABLE.data-table")[0].dispatchEvent(new Event(newEventString));
    });

    // Initialize datatable event
    $wrapper.on('init.dt', function(e, settings) {

      const api = new $.fn.dataTable.Api(settings)
      const state = api.state.loaded()

      // Initially hide all invicible columns
      const columns = table.settings().init().columns
      columns.forEach((col, i)=>{

        // Hide all invisible columns
        if(col.visible == "never"){
          table.column(i).visible(false)
        }

      })

      // Set search value on state-save if it exist
      if (state) {
        $wrapper.find(".table-search").val(state.search.search)
      }

      $wrapper.find(".column-menu INPUT[type=checkbox]").each((i, checkbox) => {
        let c_index = checkbox.value

        if(state){

          // Set the checkbox state from state
          let is_visible = state.columns[c_index].visible
          checkbox.checked = is_visible

        } else {

          // Set column visibility
          let is_visible = checkbox.checked
          table.column(c_index).visible(is_visible)

        }

      })

      /*
        To enable search by column of the table given the input element
      */
      const $inputs = $element.find(".column-search :input")

      const serialize_column_value = function(){
        let params = $inputs.serialize()
        $element.find("A.create-entity").each((i, ele) => {
          const link = $(ele)
          const base_url = $(ele).attr('href').split("?")[0]

          link.attr("href", `${base_url}?${params}`)

        })
      }
      const column_search = function($input){

        const search_value = $input.val() || ""
        const column_name = $input.prop("name")
        const index = table.columns(`.${column_name}`)[0][0]

        // Return if index of that column could not be found
        if (!index) return console.warn(`Could not find the table column for ${column_name}`)

        // Column search
        table.columns(index).search(search_value)

        // Store value in data property to be compared
        $input.data("stored-value", search_value)

      }

      if ($inputs.length) {

        $element.on("keyup change", ".column-search :input",
          _.debounce((e) => {

            const $input = $(e.target)

            // Short-circuit, dont do anything if no value change
            if ($input.val() == $input.data("stored-value")) return

            // Do nothing if it's a tab
            if (e.keyCode == 9) return

            // Search datatable column by input value and draw it
            column_search($input)
            table.draw()

            // Add search input value to the create new entity link
            serialize_column_value()

            // Show the data table after draw event
            $element.on("draw.dt", () => $element.find(".collapse").collapse("show"))

            // Disable and ask user to select an entity
            $element.find("INPUT[type=submit]").addClass("disabled")
          }, window.DEBOUNCE_THRESHOLD)
        )
      }

      // Show the table once initialized
      $wrapper.find("TABLE.data-table").removeClass("hidden")

    })

    // Set up the custom search
    $wrapper.find("INPUT.table-search").keyup(
      _.debounce((event) => {
        table.search($(event.target).val()).draw()
      }, window.DEBOUNCE_THRESHOLD)
    )

    // Focus input on click search icon
    $wrapper.find(".search-icon").on("click", () => {
      $wrapper.find("INPUT.table-search").focus()
    })

    // Handle the datatable number of rows
    $wrapper.find(".total-rows__item").on("click", function(event) {
      const $item = $(event.target)
      const num = _.toNumber($item.text())

      // Change the num of datatable rows and redraw
      table.page.len(num).draw()

      // Store the length menu to LocalStorage to be applied to all table
      localStorage.setItem("datatable_total_rows", num)

      // Remove other active class and set active
      $item.parent().siblings().find("A").removeClass('active')
      $item.addClass('active')

    })

    // Show and hide columns
    $wrapper.find(".column-menu .js-column-item").bind("click", function(event){
      event.stopImmediatePropagation()

      // columns checkboxes click event
      if ($(event.target).is(':checkbox')) {

        const is_checked = event.target.checked

        // Set colummn visibility on checkbox click
        table.column(event.target.value).visible(is_checked)
        table.columns.adjust().draw() // Recalculate column widths
      }

    })

    // On Filter click, draw the table to trigger AJAX and filter the table data
    $wrapper.find(".date-filter INPUT, .division-filter INPUT, .delivery-mode-filter INPUT, .entity-type-filter INPUT, \
                   .survey-status-filter INPUT, .user-status-filter INPUT").on("change", _.debounce(function(event){
      table.draw()
    }, window.DEBOUNCE_THRESHOLD))

    // Handle checkbox on division filter
    $wrapper.on("change", ".division-filter INPUT, .entity-type-filter INPUT", function(event){

      const $checkbox = $(event.target)
      const $container = $checkbox.parents(".division-filter")

      if ($checkbox.val().length) {

        // Uncheck the all divisions checkbox
        $container.find("INPUT[value='']").prop("checked", false)

      } else {

        // Clear other checkbox if its filter by all
        $checkbox.parent().siblings().find("INPUT").prop("checked", false)
        $checkbox.prop("checked", true)

      }

      // Check All divisions if nothing is checked
      if (!$container.find("INPUT:checkbox:checked").length) {
        $container.find("INPUT[value='']").prop("checked", true)
      }

    })

    $wrapper.find("TABLE.data-table tbody").on("click", "tr", function(event){

      // Do not open dialog if it's a highlight
      if (getSelection().toString().length) return

      const row_data = table.row(event.currentTarget).data()

      // For entity search, select a radio button instead
      if (row_data && row_data.selected_entity_id) {
        return $(this).find("INPUT[name=entity_id]").prop('checked', true).trigger("change")
      }

      // Open dialog box if it's a TD
      if (row_data && $(event.target).is("TD")) {
        if (row_data.DT_RowData.bypass_modal) {
          window.location = row_data.DT_RowData.link
        } else {
          // Open a modal
          actions.openDialog({url: row_data.DT_RowData.link})
        }
      }

    })

  })

}
