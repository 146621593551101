/*
  On new field, disabled set primary if input field is empty
*/
$(document).on("keyup", ".c-resolve-bounce-issue .js-new-value", _.debounce((event) => {

  const $input = $(event.target)
  const $form = $input.parents("FORM")
  const $radio = $input.parents("TR").find(".js-make-primary")

  if ($input.val().length) {
    $radio.attr("disabled", false)
  } else {
    $radio.attr("disabled", true)
  }

}, window.DEBOUNCE_THRESHOLD))
