const thunk = require('redux-thunk').default
const reducers = require('../reducers').default

// Middleware
let middleware = [
  Redux.applyMiddleware(
    thunk
  )
]

// Create the store factory
module.exports = function(initialState){
  return Redux.createStore(
    reducers,
    initialState,
    Redux.compose(...middleware)
  )
}
