/*
  Manage collapse to show more/less entity association data
*/
$(document).on("click", ".c-toggle-association", function(event){

  const $ele = $(event.target)
  const $collapseable = $ele.parents(".c-association-group").find(".row.collapse")

  $collapseable.collapse("toggle")

  // If it shows then change HTML to Show less, otherwise show number of hidden records
  if ($ele.data("collapsed")) {

    $ele.html("Show less...")
    $ele.data("collapsed", false)

  } else {

    $ele.html(`Show ${$ele.data('count')} more...`)
    $ele.data("collapsed", true)

  }

})
