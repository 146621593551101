export default (state = [], action) => {

  try {

    // Adds a set of intents to the action stack
    if (action.type == "intents/add"){

      if(!action.payload.success) throw "Intent requires a success action"
      if(!action.payload.cancel) throw "Intent requires a cancel action"

      // Add the intent
      state = [...state, action.payload]

    }

    // Removes an intent from the stack
    if(action.type == "intents/remove"){
      state = state.slice(0, -1)
    }

  } catch (err) {
    console.error(err + ". Failed action:", action)
  }

  return state

}
