/*
  Manage sorting crime category ranking by severity
  This will update the severity value of a category based on the order of the index
*/
export default function($element){
  $element.find(".c-category-severity").each(function(idx){
    const $wrapper = $(this)
    $wrapper.sortable({
      stop: function(event, ui) {
        $wrapper.find("li").each((i, element) => {

          // Change the display severity value
          let $li = $(element)
          let severity = i + 1
          $li.find(".c-category-severity__rank").html(severity)

          // Change the severity value of the form input
          $li.find("INPUT[name*=severity]").val(severity)
        })
      }
    })
  })
}
