document.addEventListener("turbo:load", () => {
  $(".c-new-admin-settings__js-dropdown").select2({
    tags: true
  });

  // The use custom toggle causes any input elements in the next column to toggle on/off
  $('.c-new-admin__use-custom').click(function() {
    $(this).closest("TD").next("TD").find(":input").prop('disabled', !$(this).is(':checked'))
  })
});
