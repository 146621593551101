/*
  Handle top nav user dropdown
*/
$(document).on('click', '.c-top-nav-dropdown', function (event) {

  const $wrapper = $(event.currentTarget)
  const $menus = $wrapper.find('.c-top-nav-dropdown__menus')

  $menus.toggleClass('hidden')

  $wrapper.off('keyup.esc').on('keyup.esc', event => {
    if (event.keyCode == 27) hideMenu()
  })

  $(document).off('click.hideMenu').on('click.hideMenu', event => {

    // Hide the menu when the user click outside of the component boundary
    if (!$(event.target).closest('.c-top-nav-dropdown').length) hideMenu()

  })

  function hideMenu() { $menus.addClass('hidden') }

})
