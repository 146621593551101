/*
  Handle FORM submission
*/
$(document).on("submit", "FORM", function(event){

  // Form reference
  const $form = $(event.target)
  const $submit_btn = $form.find("INPUT[type=submit]")

  // Short-circuit
  if($form.is(".js-ajax-bypass")) return

  // Catch the submission
  event.preventDefault()

  const url = $form.attr("action")
  const method = $form.find("[name=_method]").val() || $form.attr("method") || "POST"

  // Clear old error messages
  $form.find("DIV.alert.alert-danger").remove()

  // Construct the data
  let data = null
  if(method.toLowerCase() === "get") {
    data = $form.serialize()
  } else {
    data = new FormData(this)
  }

  // Include file format if it exist
  if ($form.find("INPUT[type=file]").length) {
    $form.find("input[type=file]").each((i, ele) => {

      // Add logo to formdata https://abandon.ie/notebook/simple-file-uploads-using-jquery-ajax
      $.each(ele.files, (k, v) => {data.append(ele.name, v)})
    })
  }

  if (method.toLowerCase() === "get") {
    actions.closeDialog()
    return actions.openDialog({url: url + "?" + data})
  }

  $.ajax({
    method: method,
    url: url,
    data: data,
    cache: false,
    dataType: "json",
    processData: false, // Don't process the files
    contentType: false, // Allow the browser to figure this out
    beforeSend: function(){

      // Add waiting cursor and UI
      $("BODY").addClass("u-wait")

      if($submit_btn.val() === "Save") {$submit_btn.val("Saving...")}

    },
    complete: function(){

      // Remove waiting cursor and UI
      $("BODY").removeClass("u-wait")

      if($submit_btn.val() === "Saving...") {$submit_btn.val("Save")}
    }
  })
  .then(

    // handle success message
    actions.handleAPISuccess()
  )
  .fail((err)=>{

    // Remove disabled
    $submit_btn.prop("disabled", false)

    // Failure
    actions.handleAPIError({
      url: url,
      method: method,
      form: $form
    })(err)
  })

})
