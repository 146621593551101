function intentFactory(type){

  // Builds an action creator
  return (payload, meta) => {

    // Redux thunk of a Flex Standard Action
    return (dispatch) => {
      const action = {
        type,
        payload,
        meta
      }
      dispatch(action)
    }

  }
}

module.exports = {

  // Dialog intents
  openDialog: intentFactory('dialog/open'),
  closeDialog: intentFactory('dialog/close'),
  closeAllDialogs: intentFactory('dialog/closeAll')
}
