/*
  Hide the remove-field at initial load if its only one copy
*/
$(document).on("show.bs.modal", ".modal", function(event){

  const $groups = $(event.target).find(".c-association-group")

  if($groups.length){
    $groups.each((i, group) => {

      const $copies = $(group).find(".copy")
      if($copies.length <= 1){
        $copies.first().find(".remove-field").hide()
      }
    })
  }
})

/*
  Remove a nearest group of fields from the element
*/
$(document).on("click", ".c-association-group .remove-field", function(event){
  event.preventDefault()

  const $button = $(event.target)
  const $group = $button.parents(".c-association-group")

  // Remove the copy
  $button.parents(".copy").remove()

  // Remove the fields group if count is more than 1
  if($group.find(".copy").length <= 1){
    $group.find(".copy").first().find(".remove-field").hide()
  }
})

/*
  Add a copy of empty group fields to the button
*/
$(document).on("click", ".c-association-group .add-field", function(event){
  event.preventDefault()

  const $button = $(event.target)
  const $last = $button.parent().siblings(".copy").last()

  // Show the remove button for the first copy
  $button.parent().siblings(".copy").first().find(".remove-field").show()

  // Clone the last fields, according to index
  const $cloned_fields = $last.clone()

  // Remove field value, excluding .make_primary, and make_unsubscribe
  $cloned_fields.find("INPUT").not(".make_primary").not(".make_unsubscribe").val("")

  // Deselect .make_primary & .make_unsubscribe
  $cloned_fields.find("INPUT.make_primary").prop("checked", false)
  $cloned_fields.find("INPUT.make_unsubscribe").prop("checked", false)

  // Deselect any selected item in any form dropdown
  $cloned_fields.find("OPTION:selected").prop("selected", false)

  // Set select option to blank value
  $cloned_fields.find("SELECT").each((i, select) => {

    const $select = $(select)
    const blank_option = $select.find("option[value='']")

    if(blank_option.length){

      // Select the blank value
      blank_option.attr("selected", true)

    } else {

      // Prepend and select blank option if its not exist
      $select.prepend("<option value=''>Please Select</option>").val('');

    }
  })

  // Remove hidden Id field
  $cloned_fields.find("INPUT[type=hidden]").remove()

  // Increment index
  $cloned_fields.find(":input").attr("name", (i, val) => {
    return val.replace(/\[(\d+)\]/, (_, m) => {
      return "[" + (+m + 1) + "]"
    })
  })

  // Add fields
  $last.after($cloned_fields)
})

/*
  Handle checking and unchecking checkboxes on primary field for new/update entities
*/
$(document).on("change", ".c-association-group .make_primary", function(event){

  const $checkbox = $(event.target)

  // Uncheck siblings
  $checkbox.parents(".copy").siblings().find(".make_primary").prop("checked", false)

  // Always set primary to clicked on
  $checkbox.prop("checked", true)
})
