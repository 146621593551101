/*
  To Adjust Iframe height
*/
export default function ($element) {
  $element.find(".js-adjust-iframe-height").each(function (idx) {

    const $iframe = $(this)

    // Trigger adjust Iframe height after iframe src is set
    $iframe.on("load", () => {

      if (!this.src) return

      this.contentWindow.requestAnimationFrame(() => {
        $iframe.animate({
          height: this.contentWindow.document.body.offsetHeight
         }, 0)
      })

    })

    // Set Iframe source on bootstrap event
    $iframe.parents('.modal').on("shown.bs.modal shown.bs.tab", event => {

      // The Iframe is set inside a tab-pane
      // Only set when iframe tab-pane is active
      // Otherwise the height won't be calculated properly
      if ($iframe.parents(".tab-pane").is('.active') && !this.src) {
        this.src = $iframe.data("url")
      }

    })

  })

}
